/* Styles go here */

.page-header,
.page-header-space {
  height: 100px;
  display: none;
}

.page-footer,
.page-footer-space {
  height: 50px;
  display: none;
}

.page-footer {
  position: fixed;
  bottom: -1.25in !important;
  width: 100%;
  font-size: 10px;
  display: none;
}

.page-header {
  position: fixed;
  top: 0in;
  left: 4in;
  width: 100%;
  display: none;
}

.page {
  page-break-after: always;
}

@page {
  margin: 10mm;
  size: 8.5in 11in;
  width: 100%;
  background-color: #fff;
}

@media print {
  body {
    font-family: Urbanist, sans-serif;
    margin: 1in 1in 1in 1in;
    background-color: #fff;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  .page-header {
    display: block;
  }
  .page-footer {
    display: block;
  }
  .page-header-space {
    display: block;
  }
  .page-footer-space {
    display: block;
  }
  .no-print {
    display: none !important;
  }
  .header-image {
    width: 1in;
  }

  button {
    display: none;
  }

  body {
    margin: 0;
  }
}
